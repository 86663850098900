import React, { PureComponent } from "react";

import styles from "./Main.module.scss";

import ClientBase from "./ClientBase";

import Video from "../../img/client_pages/mossy/video.mp4";
import Feature from "../../img/client_pages/mossy/feature.png";
import Design from "../../img/client_pages/mossy/design.png";
import Iphone from "../../img/client_pages/mossy/social.png";

export default class Mossy extends PureComponent {
  render() {
    return (
      <ClientBase
        mainImgStyle={styles.MossyMainImg}
        video={Video}
        title="Mossy Nissan"
        theBrand={
          "Mossy Nissan is a family-owned, family-driven dealership boasting four generations of service to the Houston area. Their dedication to evolution has helped them expand to serve customers across the country."
        }
        ourWork={["Brand Identity", "Web Design"]}
        brandDesign={
          "Our strategy was to appeal to their core audience by emphasizing family, reliability, and customer care. We incorporated simple graphics, powerful imagery, and a vibrant color palette to form a consistent visual identity, build brand recognition, and secure customer trust."
        }
        featureImg={Feature}
        webDesignText={
          "Working closely with the Mossy team, we found creative solutions to customize their current site framework. Aside from the visual facelift, we went deeper to understand the user’s journey and design Mossy’s website with their needs as a priority."
        }
        webDesignImg={Design}
        socialDesignText={
          "Inspired by the Mossy family legacy, we decided to pay homage by incorporating a subtle design element into most promotional materials. The Mossy watermark highlights key elements of certain graphics, and draws the reader’s eye from one part of the graphic to another."
        }
        socialDesignImg={Iphone}
      />
    );
  }
}
