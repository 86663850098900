import React, { PureComponent, Fragment } from "react";

import "../styles/main.scss";

import Seo from "../components/Seo";
import Mossy from "../components/ClientPages/Mossy";

export default class MossyNissanPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <Seo title="Mossy Nissan" />
        <Mossy />
      </Fragment>
    );
  }
}
